.popupdiv{
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 110vh;
    background-color:rgba(38, 37, 37, 0.9);
    backdrop-filter: blur(4px); 
    transform: translate(-50%,-50%);
    z-index: 1099;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
  .contactPage{
    width: 40%;
    background: linear-gradient(black, black) padding-box,
    linear-gradient(320deg, transparent 0%, #e81cff, #40c9ff) border-box;
  padding: 10px;
  transform-origin: right bottom;
  border: 2px solid transparent;
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    padding:20px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
  }
  .contactPage h1{
    color: white;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    font-family: "Fjalla One", sans-serif;
    letter-spacing: 1.5px;
   
  }

.contactPage span{
    color: goldenrod;
}
  .contactform{
    
    margin-top: 20px;
    width: 80%;
    display: flex;
    flex-direction: column;
    
    justify-content: center;
    align-items:center;
    
  }
  .email,.fullname{
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 40px 10px 10px;
    border: 1.5px solid black;
    border-radius: 4px;
    font-size: 16px;
  
    letter-spacing: 1px;
    background-color: rgb(255, 251, 243);
    color: black;
  }
  .email:focus,.fullname:focus{
    outline: none;
  }
  
  .submitbtn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    background-color:#036eb5;
    color: white; 
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s ease, letter-spacing 0.2s ease;
  }
  
  .submitbtn:hover {
    background-color: #2980b9;
    color: #ffffff; 
    transform: translateY(10%);
    letter-spacing: 1px;
  }

.close-btn{
    position: absolute;
    top: -50px;
    right: -50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    animation: none;
    box-shadow: none;
    padding: 0;
    background: none;
    cursor: pointer;

  }
  .close-btn img:hover{
    background-color:red;
  }
  .close-btn img{
    width: 43px;
    border: 2px solid white;
    border-radius: 50%;
    background-color: white;
  }



  @media screen and (max-width: 767px) {

    .contactPage{
        width: 100%;
        box-shadow: 0 0 20px 10px rgba(12, 12, 12,0.3);

    }
    
  .contactPage h1{
    font-size: 18px;
  }
  .contactform{
    width: 100%;
  }
  .close-btn{
    
    top: -50px;
    right: -5px;
    width: 30px;
    

  }
  .close-btn img{
    width: 40px;
    
  }
    
}
  
